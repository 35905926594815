import React, {Component} from "react";
import styled from "styled-components";
import Container from "../Core/Container";
import {Row, Col} from "styled-bootstrap-grid";
import breakpoints from "../../styles/breakpoints";
import Section from "../Core/Section"

//imports
import mapboxgl from '../../assets/javascript/mapboxgl.js'
import  '../../assets/styles/mapboxgl.css'


const Styles = styled.div`
  height: 26.25rem;

  @media only screen and (min-width: ${breakpoints.desktop}) {
    height: 36.25rem;
  }
`;

class Map extends Component {

  constructor(params) {
    super(params);
    this.ref = React.createRef()
}

  createMap = () => {
    let app = this, 
        map = app.ref.current,
        coords = app.props.location

    mapboxgl.accessToken = "pk.eyJ1Ijoic25hcHRyaXBrcmlzIiwiYSI6ImNqa2RvejJucDB5bm4zcnM1ZDlyOHVqZnQifQ.VZTOARdDaid-d4yf2Q182g";

    app.mapCore = new mapboxgl.Map({
        container: map,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: coords,
        zoom: 11
    });
    app.mapCore.addControl(new mapboxgl.NavigationControl(), 'top-left');
    app.mapCore.addControl(new mapboxgl.ScaleControl({ unit: "imperial" }));

    new mapboxgl.Marker().setLngLat(coords).addTo(app.mapCore);

    // disable map zoom when using scroll
    app.mapCore.scrollZoom.disable();
}

  handleScroll = (e) => {
      let app = this;
      let scroll = e.currentTarget.pageYOffset;
      let map = app.ref.current.getBoundingClientRect().top;
      let isShowing = app.inViewport(app.ref.current);
      if (scroll > map && isShowing) {
          app.createMap();
          window.removeEventListener('scroll', this.handleScroll);
      }
  }

  inViewport = (el) => {
      let box = el.getBoundingClientRect();
      return (
          box.top >= 0 &&
          box.left >= 0 &&
          box.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          box.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
  }

  componentDidMount () {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount () {
    if (typeof window !== `undefined`) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  render() {
    return (
      <Section id={this.props.id ? this.props.id : null}>
        <Container>
            <Row>
              <Col lg={9}>
                <Styles ref={this.ref} id="map"></Styles>
              </Col>
            </Row>
        </Container>
      </Section>   
    )};
};

export default Map;