import React, { Component } from 'react';
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from 'styled-components';
import breakpoints from "../../styles/breakpoints";
import { Row, Col } from "styled-bootstrap-grid";
import Container from "../Core/Container";
import Section from "../Core/Section";
import Icon from "../Core/Icon";


const CardList = styled.ul`

    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1.25rem;
    padding: 0;
    margin: 0;

    @media only screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-width: ${breakpoints.desktop}) {
        grid-template-columns: 1fr 1fr 1fr;
    }

`;

const Tooltip = styled.span`
    position: absolute;
    top: -1.7rem;
    z-index: 2;
    left: 50%;
    color: #fff!important;
    text-transform: capitalize;
    font-size: .75rem;
    background: rgba(0,0,0,0.8);
    width: max-content;
    padding: 0.5rem;
    border-radius: 0.4rem;
    opacity: 0;
    transition: opacity .5s ease;
    transform: translateX(-50%);

    .icons:hover > & {
        opacity: 1;
    }

`;

const Card = styled.li`

    min-height: 15.75rem;
    width: 100%;
    box-shadow: 0px 3px 0.2rem 0px rgb(0 0 0 / 30%);
    border-radius: .25rem;
    position: relative;

    .gatsby-image-wrapper {
        height: 23.75rem;
        width: 100%;
    }

    .property-container {
        padding: 1.25rem;
    }

    h3 {
        font-size: 1.5rem;
        margin: 0;
    }

    p {
        margin-bottom: 1.25rem;
    }

    a {
        border: none;
        background: var(--secondary);
        color: var(--white);
        cursor: pointer;
        box-sizing: border-box;
        padding: 15px 45px;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        display: block;
        font-size: 0.875rem;
        margin: 0 auto;
        text-decoration: none;
        position: absolute;
        bottom: 1.25rem; 
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;

        &:hover,
        &:focus {
            background: var(--lightenButton);
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 1.25rem;
        display: flex;
    }

  li {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 1.25rem;
      z-index: 3;
  }

  .icon {
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.5rem;
      background: var(--primary);
      fill: var(--white);
      border-radius: 50%;
  }

`;

const CardContainer = styled.div`

        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        text-decoration: none;
        box-sizing: border-box;
        background: #fff;
        position: relative;
        font-size: 1.5rem;
        color: #000;
        text-decoration: none;
        position: relative;

        .margin,
        .price {
            margin-bottom: 4.375rem;
        }
`;


const UnitList = (props) => {

    const cards = props.cards;

    return (
    <Section id={props.id ? props.id : null}>
      <Container>
        <Row>
          <Col lg={12} >
            <div>
                {props.heading ? <h2>{props.heading}</h2> : ""}
                {props.tagline ?<p>{props.tagline}</p>: ""}
                <CardList property={props.property}>
                    {cards.map(function(card, index){
                        let entryPage,
                            exitPage;
                
                        if (typeof window !== "undefined") {
                            entryPage = "https://www.holidayparks.com" + window.sessionStorage.getItem("landingUrl");
                            exitPage = window.location.href;
                        }

                        let url 
                        url = card.url ? card.url.includes('utm') ? card.url : card.url + `?utm_source=holidayparks&utm_medium=referral&utm_campaign=lodge-Unlabelled-landing-${entryPage}-click-${exitPage}`: null;

                        return ( 
                            <Card key={ index } className="grid-item">
                                <CardContainer>
                                    <Img fluid={card.image.image.asset.fluid} alt={card.image.alt} />
                                    <div className="property-container">
                                        <h3>{card.name}</h3>
                                        <ul>
                                          {card.facilities.map(function(facility, index){
                                            return ( 
                                              <li className="icons" key={index} title={facility}>
                                                <Tooltip>{facility}</Tooltip>
                                                <Icon name="icon-star" />
                                              </li>
                                            )
                                          })}
                                        </ul>
                                        <p className="margin">{card.description}</p>
                                        {url && <a href={url} target="_blank">View Unit</a>}
                                    </div> 
                                </CardContainer>
                            </Card>
                        )

                    })}
                </CardList>
            </div>
          </Col>
        </Row>
      </Container>
      </Section>
    );
};

export default UnitList;